import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const IllustrazioneModelloIntegrato = ({
  location,
  pageContext: { langCode },
}) => {
  const [selectedArea, setSelectedArea] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);
  const slideRef = useRef([]);

  useEffect(() => {
    slideRef.current.forEach((slide, i) => {
      const slideWidth = slide.clientWidth;
      slide.style.transform = `translateX(-${selectedArea * slideWidth}px)`;
    });
  }, [selectedArea, slideRef]);

  const loadNextContent = useCallback(() => {
    setSelectedArea((current) => (current + 1) % slideRef.current.length);
  }, []);

  const loadPrevContent = useCallback(() => {
    setSelectedArea(
      (current) =>
        (current - 1 + slideRef.current.length) % slideRef.current.length
    );
  }, []);

  useEffect(() => {
    const scaleFactor = () => {
      if (window.innerWidth > window.innerHeight) {
        const scaling = (window.innerHeight - 75) / 900;
        document.documentElement.style.setProperty("--scale", scaling);
      } else {
        const scaling = window.innerWidth / 1920;
        document.documentElement.style.setProperty("--scale", scaling);
      }
    };
    scaleFactor();
    window.addEventListener("resize", scaleFactor);
    return () => window.removeEventListener("resize", scaleFactor);
  }, []);

  useEffect(() => {
    const pressKey = (e) => {
      if (e.key === "ArrowRight") {
        loadNextContent();
      }
      if (e.key === "ArrowLeft") {
        loadPrevContent();
      }
      if (e.key === "Escape") {
        setModalOpen(false);
        modalRef.current.classList.remove("open");
      }
    };
    document.addEventListener("keydown", pressKey);
    return () => document.removeEventListener("keydown", pressKey);
  }, [loadPrevContent, loadNextContent]);

  return (
    <Layout
      langCode={langCode}
      location={location}
      headerVisible={false}
      footerVisible={false}
    >
      <Seo
        title={"Illustrazione modello integrato"}
        langCode={langCode}
        description={"Illustrazione modello integrato"}
        robots={["none"]}
      />
      <div className="energy__wrapper">
        <div className="energy__title">
          Scopri ENGIE: tocca la mappa per iniziare!
        </div>
        <div className="energy__scaling">
          <img
            id="animation"
            src={process.env.GATSBY_CORPORATE_ANIMATION}
            alt=""
            useMap="#engie_map"
            className="energy__animation"
          />
          <map name="engie_map">
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(8);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="1058,99,64"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(9);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="1261,189,59"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(10);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="1361,318,58"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(15);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="1351,456,61"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(11);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="916,197,55"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(4);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="1113,463,60"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(5);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="782,494,54"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(6);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="508,354,57"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(1);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="641,267,52"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(0);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="767,285,54"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(12);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="827,679,47"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(13);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="759,758,45"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(14);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="644,528,48"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(3);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="537,588,45"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(7);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="547,718,43"
              shape="circle"
              className="energy__area"
            />
            <area
              alt=""
              title=""
              onClick={() => {
                setSelectedArea(2);
                setTimeout(() => {
                  setModalOpen(true);
                }, 100);
              }}
              coords="466,656,41"
              shape="circle"
              className="energy__area"
            />
          </map>
        </div>
        <div
          id="modal"
          ref={modalRef}
          className={`energy__modal ${modalOpen ? "open" : ""}`}
        >
          <div className="energy__modal-wrapper">
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[0] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_1}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  La nostra mission è accelerare la Transizione Energetica
                  raggiungendo la Carbon Neutrality entro il 2045. Siamo attivi
                  su tutta la filiera dell'energia: dalla produzione e lo
                  sviluppo di capacità installata da fonti rinnovabili, al
                  trading di energia, alle soluzioni che accompagnano persone,
                  aziende e pubblica amministrazione in un percorso virtuoso per
                  imparare a consumare l'energia meno e meglio. Siamo un player
                  mondiale, e in Italia siamo presenti su tutto il territorio
                  nazionale, con un quartier generale a Roma e uno a Milano, che
                  puoi vedere nella foto qui accanto.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    1 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[1] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_2}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    GLOBAL ENERGY MANAGEMENT & SALES
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  GEMS, Global Energy Management & Sales, è la nostra
                  piattaforma commerciale trasversale ai diversi business, che,
                  attraverso un'attività di trading, si occupa di assicurare le
                  forniture di energia e assolve la funzione di risk management
                  per le attività dei nostri business. GEMS assicura uno
                  sviluppo continuo delle rinnovabili: infatti queste risorse
                  sono imprevedibili e non programmabili e hanno bisogno di
                  essere supportate da un sistema di back up e valorizzazione
                  del loro potenziale.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    2 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[2] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_3}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, PER I COMUNI
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  I Comuni sono uno dei partner più importanti per noi di ENGIE.
                  Affiancandoli nella gestione dei loro consumi energetici, li
                  accompagniamo in un processo di efficientamento e di
                  decarbonizzazione, con un impatto positivo in termini
                  economici e ambientali. Studiamo e ottimizziamo i bisogni
                  degli edifici pubblici adottando soluzioni semplici e
                  innovative, riqualifichiamo e miglioriamo l'illuminazione
                  pubblica con LED ad alta efficienza e, con un lightning design
                  ad hoc, valorizziamo il patrimonio storico e artistico delle
                  città. Questa e altre tecnologie ci permettono di traghettare
                  i comuni partner in un percorso per diventare "Smart Cities":
                  tra gli esempi più virtuosi il Comune di Livorno.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    3 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[3] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_4}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, PER GLI OSPEDALI
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Noi di ENGIE in Italia gestiamo oltre 80 presidi sanitari
                  sull'intero territorio nazionale. Strutture simili richiedono
                  una particolare attenzione alla gestione di temperatura,
                  qualità dell'aria, sicurezza della fornitura energetica e
                  servizi di facility management molto complessi. Garantiamo il
                  giusto supporto con alte competenze, con un know-how specifico
                  e verticale per questo settore. Tra le strutture che gestiamo,
                  anche l'Ospedale di Bassiano a Bassano del Grappa (VI).
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    4 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[4] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_5}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, PER LE AZIENDE
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Per ogni azienda individuiamo le soluzioni migliori
                  personalizzate in grado rispondere al meglio alle specifiche
                  esigenze di consumo. Per esempio, tra i settori industriali
                  che supportiamo, c'è il farmaceutico: queste aziende
                  farmaceutiche hanno spesso necessità di avere a disposizione
                  energia termica, elettrica e frigorifera - bisogno che
                  accogliamo proponendo soluzioni combinate, come trigenerazione
                  e fotovoltaico, garantendo una risposta ottimale alla loro
                  domanda di energia e una riduzione dei costi di
                  approvvigionamento della stessa. Tra i casi più virtuosi, lo
                  stabilimento di Sanofi a Scoppito (AQ).
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    5 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[5] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_6}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, PER I MUSEI
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  In Italia viene custodito quasi l'80% del patrimonio artistico
                  mondiale: un dato che evidenzia l'importanza per musei
                  pubblici e privati di custodire e preservare al meglio le
                  opere d'arte, valorizzandone la magnificenza. In quest'ottica,
                  ENGIE ha sviluppato un'offerta in grado di portare nei siti
                  d'interesse un'energia "a regola d'arte": efficienza
                  energetica, controllo termo-igrometrico e illuminazione LED
                  con lighting design dedicato sono abbinati a soluzioni per
                  l'autoconsumo energetico. Ne è un esempio il Museo e Real
                  Bosco di Capodimonte, dove tra le innovazioni vi sono anche le
                  tegole fotovoltaiche, che sfruttano il tetto della struttura
                  nel rispetto dei vincoli di tutela del patrimonio culturale.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    6 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[6] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_7}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, PER DISTRETTI E GRANDI CONDOMINI
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Siamo l'interlocutore unico, affidabile e qualificato, che
                  accompagna i condomìni e gli amministratori che li gestiscono
                  in un percorso di sostenibilità, volto a ridurre i consumi
                  energetici e aumentare la sicurezza del complesso
                  residenziale. Lo facciamo a partire da una diagnosi
                  energetica, per arrivare a individuare le opportunità di
                  riqualificazione ed efficientamento degli edifici.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    7 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[7] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_8}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, CON LA MOBILITÀ ELETTRICA
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Accompagniamo l'adozione di soluzioni sostenibili per la
                  mobilità, con colonnine di ricarica per veicoli elettrici, che
                  completano la nostra offerta per partner pubblici e privati.
                  Anche questa attività contribuisce ad accelerare la
                  transizione energetica, decarbonizzando i territori.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    8 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[8] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_9}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, CON I NOSTRI IMPIANTI EOLICI
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  In Italia abbiamo 24 impianti eolici, distribuiti in
                  prevalenza in Sicilia, Sardegna e Puglia, per un totale di
                  500MW. Ci occupiamo dello sviluppo, della costruzione, di O&M
                  e di performance. Controlliamo che il funzionamento delle
                  macchine sia ottimale in ogni momento e contribuiamo con la
                  nostra produzione a immettere energia pulita nella rete
                  elettrica nazionale. Nella foto, il nostro impianto di Marsala
                  (TP).
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    9 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[9] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_10}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, CON LE NOSTRE SOTTOSTAZIONI
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  I grandi impianti di produzione da fonte rinnovabile hanno
                  sempre in loro prossimità una sottostazione elettrica: in
                  ENGIE ci occupiamo anche della loro costruzione. Le
                  sottostazioni ricevono l'energia prodotta dagli impianti, ne
                  innalzano la potenza e successivamente fanno sì che questa
                  confluisca nella rete elettrica nazionale.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    10 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[10] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_11}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, CON I NOSTRI IMPIANTI FOTOVOLTAICI E
                    AGRIVOLTAICI
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Il fotovoltaico - e l'agrivoltaico - sono tecnologie
                  fondamentali per accelerare la transizione energetica:
                  dall'energia del sole, riusciamo a produrre elettricità per
                  gli utenti finali. Il fotovoltaico può essere in prossimità
                  dell'utilizzatore finale, oppure "off-site": è il caso del
                  nostro impianto agrivoltaico di Mazara del Vallo, realizzato
                  in partnership con Amazon. L'impianto è agrivoltaico perché
                  coniuga la vocazione agricola del territorio con la produzione
                  di energia.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    11 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[11] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_12}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, CON LE NOSTRE BESS
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Le BESS, ossia le tecnologie di accumulo, sono fondamentali
                  per garantire la disponibilità dell'energia rinnovabile
                  prodotta anche successivamente rispetto al momento in cui
                  questa viene prodotta. Per questo motivo, il nostro sviluppo
                  di impianti di accumulo viaggia di pari passo con quello di
                  impianti di produzione eolici e fotovoltaici, garantendone la
                  flessibilità.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    12 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[12] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_13}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, PER LA TUA CASA
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Miglioriamo l'energia della tua casa con offerte luce e gas
                  pensate per le tue necessità -- e non solo. A queste si
                  aggiungono le offerte di efficienza energetica come caldaia,
                  pompa di calore o clima, con i servizi connessi di assistenza
                  tecnica e manutenzione. La nostra priorità sono i nostri
                  clienti: per questo abbiamo un servizio di customer care
                  sempre attivo e pronto a rispondere alle tue richieste.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    13 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[13] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_14}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, CON LE RETI DI TELERISCALDAMENTO
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Con oltre 220km di rete gestita e l'equivalente di 165.000
                  abitanti che ne beneficiano, noi di ENGIE siamo leader del
                  teleriscaldamento: una soluzione che permette di produrre
                  calore fuori dal centro cittadino e portarlo sino alle utenze
                  finali attraverso una rete sotterranea di tubazioni. Un po'
                  come a New York, dove i tombini fumanti sono l'indicatore che
                  anche la Grande Mela ha adottato la stessa soluzione di Aosta,
                  Biella, Settimo Torinese...e molti altri!
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    14 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[14] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_15}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, PER LE SCUOLE
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  Tra gli enti pubblici che accompagniamo nel percorso di
                  decarbonizzazione, ci sono anche le scuole: noi di ENGIE
                  realizziamo progetti per rendere i luoghi dell'istruzione più
                  sostenibili, facendo sì che essi stessi siano esempio di
                  cambiamento e tutela della sostenibilità ambientale e sociale,
                  guardando al futuro. Tra i progetti più innovativi, quello di
                  Fiumicino, dove abbiamo accompagnato tutte le scuole del
                  territorio in una transizione full-electric e dove gli
                  studenti sono stati coinvolti nel programma di formazione "A
                  scuola per il Pianeta".
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    15 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="energy__modal-content"
              ref={(ref) => {
                slideRef.current[15] = ref;
              }}
            >
              <img
                id="imageModal"
                src={process.env.GATSBY_SLIDE_16}
                alt=""
                className="energy__detail"
              />
              <div className="energy__modal-body">
                <div className="energy__modal-header">
                  <p id="titleModal" className="energy__modal-title">
                    SIAMO ENGIE, CON I DATACENTER
                  </p>
                  <button
                    id="closeModal"
                    className="energy__close-btn"
                    onClick={() => setModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <p id="descriptionModal" className="energy__modal-text">
                  I data center giocano un ruolo centrale nella transizione
                  digitale, ma, al contempo, il loro funzionamento comporta un
                  elevato consumo energetico. Noi di ENGIE ci occupiamo di
                  realizzare data center per aziende partner, con processi che
                  garantiscono, la sicurezza e le migliori prestazioni
                  energetiche e operative in ottica green.
                </p>
                <div className="energy__modal-footer">
                  <button
                    className="energy__prev"
                    onClick={() => loadPrevContent()}
                  >
                    &lt;
                  </button>
                  <span id="areaId" className="energy__counter">
                    16 di 16
                  </span>
                  <button
                    className="energy__next"
                    onClick={() => loadNextContent()}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IllustrazioneModelloIntegrato;
